<template>
  <div class="">
    <van-nav-bar left-arrow title='详情' fixed @click-left="goBack"/>
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('sale.基本信息') }}</div>
      <van-collapse-item :title="$t('sale.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('sale.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售合同号') }}</h5>
          <p>{{ ruleForm.contractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.合同类型') }}</h5>
          <p>{{ ruleForm.contractType | setDict('SALE_TRADE_CONTRACT_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.采购来源') }}</h5>
          <p>{{ ruleForm.purchaseSource | setDict('PURCHASE_SOURCE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.合同日期') }}</h5>
          <p>{{ ruleForm.applyDate && util.dateFormat(new Date(ruleForm.applyDate), 'yy-MM-dd') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.客户名称') }}</h5>
          <p>{{ ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.客户等级') }}</h5>
          <p>{{ ruleForm.riskLvl | setDict('CUSTOMER_LEVEL') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务城市') }}</h5>
          <p>{{ ruleForm.city }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售业务员') }}</h5>
          <p>{{ ruleForm.bizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务部门') }}</h5>
          <p>{{ ruleForm.bizDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.币种') }}</h5>
          <p>{{ ruleForm.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.合同原币金额') }}</h5>
          <p>{{ ruleForm.contractAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.汇率') }}</h5>
          <p>{{ ruleForm.exchangeRateRmb | formatAmount(8,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ functionalCurrency + $t('sale.金额') }}</h5>
          <p>{{ ruleForm.contractAmountRmb | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对美元汇率') }}</h5>
          <p>{{ ruleForm.exchangeRateUsd | formatAmount(8,false) }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.美元金额') }}</h5>
          <p>{{ ruleForm.contractAmountUsd | formatAmount }}</p>
        </div>
        <div class="items" v-show="ruleForm.orgId !== '517730389255520257' || ruleForm.contractType === '1'">
          <h5>{{ $t('sale.是否寄售业务') }}</h5>
          <p>{{ ruleForm.isConsignmentBusiness | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items" v-show="['1','2'].includes(ruleForm.contractType)">
          <h5>{{ $t('sale.溢短装率') }}%</h5>
          <p>{{ ruleForm.overLoadingRate }}%</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.生效日期') }}</h5>
          <p>{{ ruleForm.effectiveDate }}</p>
        </div>
        <div class="items" v-show="!(ruleForm.orgId === '517730389255520257' || ('3,4').includes(ruleForm.contractType))">
          <h5>{{ $t('sale.增值税率') }}</h5>
          <p>{{ ruleForm.taxRate | setDict('SALE_VAT_RATE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.预计装运期') }}</h5>
          <p>{{ ruleForm.expectedDeliveryDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.订单数量') }}</h5>
          <p>{{ ruleForm.commodityQuantity | formatAmount(6,false) }}</p>
        </div>
        <div class="items" v-show="['1','2'].includes(ruleForm.contractType)">
          <h5>{{ $t('sale.利润') }}</h5>
          <p>{{ ruleForm.profitAmount | formatAmount(2,false) }}</p>
        </div>
        <div class="items" v-show="['1','2'].includes(ruleForm.contractType)">
          <h5>{{ $t('sale.利润率') }}</h5>
          <p>{{ ruleForm.profitRate ? ruleForm.profitRate+'%':'' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.状态') }}</h5>
          <p>{{ ruleForm.status | setDict('SALE_CONTRACT_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.终结状态') }}</h5>
          <p>{{ ruleForm.finalStatus | setDict('FINAL_STATUS') }}</p>
        </div>
        <div class="items" v-show="ruleForm.contractType==='4'">
          <h5>{{ $t('sale.租赁开始日期') }}</h5>
          <p>{{ ruleForm.leaseStartDate }}</p>
        </div>
        <div class="items" v-show="ruleForm.contractType==='4'">
          <h5>{{ $t('sale.租赁结束日期') }}</h5>
          <p>{{ ruleForm.leaseEndDate }}</p>
        </div>
        <div class="items" v-show="ruleForm.contractType==='4'">
          <h5>{{ $t('sale.租赁时长') }}(月)</h5>
          <p>{{ ruleForm.leaseDuration }}</p>
        </div>
        <div class="items" v-show="ruleForm.orgId !== '517730389255520257'">
          <h5>{{ $t('sale.销售助理') }}</h5>
          <p>{{ ruleForm.assistantName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.备注') }}</h5>
          <p>{{ ruleForm.bizDesc }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.性质')" name="2">
        <div class="items">
          <h5>{{ $t('sale.是否赊销') }}</h5>
          <p>{{ ruleForm.isSoldOnCredit | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.赊销天数') }}</h5>
          <p>{{ ruleForm.soldOnCreditDay }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.资金占压利息') }}</h5>
          <p>{{ ruleForm.fundsOccupyInterest }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.年利率') }}</h5>
          <p>{{ ruleForm.annualInterestRate ? ruleForm.annualInterestRate+'%':'' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否标准合同') }}</h5>
          <p>{{ ruleForm.isStandardContract | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.同意合同盖章') }}</h5>
          <p>{{ ruleForm.isSeal | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.盖章日期') }}</h5>
          <p>{{ ruleForm.sealDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否定制商品') }}</h5>
          <p>{{ ruleForm.isCustomizeProduct | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.价格条款') }}</h5>
          <p>{{ ruleForm.priceTerms | setDict('PRICE_TERMS') }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.商品明细')" name="commodity">
        <div v-if="!ruleForm.commodityList || !ruleForm.commodityList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in ruleForm.commodityList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.商品名称') }}</h5>
            <p>{{ item.materialName }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.客户信用')" name="credit">
        <customerCredit :supplierId="ruleForm.customerId" :orgId="ruleForm.orgId"/>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.附件') }}</div>
      <van-collapse-item :title="$t('sale.附件信息')" name="3">
        <div v-if="!attachmentList || !attachmentList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.系统信息') }}</div>
      <van-collapse-item :title="$t('sale.系统信息')" name="4">
        <div class="items">
          <h5>{{ $t('sale.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.制单人') }}</h5>
          <p>{{ this.ruleForm.createUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
    import {Dialog} from 'vant';
    import util from '../../../libs/util';
    import myHistoryPanel from '@/views/business/components/myHistoryPanel';
    import myApproval from '@/views/business/components/myApproval';
    import customerCredit from '../customerCredit'

    export default {
        name: 'visitorFieldComponent',
        components: {
            myHistoryPanel,
            myApproval,
            customerCredit
        },
        data() {
            return {
              util: util,
              attachmentList: [],
              // 销售增值税率
              taxRate: '',
              // 采购增值税率
              purTaxRate: '',
              // 销售额价税合计 含税金额
              saleAmountIncludeTax: 0,
              // 不含税销售额
              saleAmountExcludeTax: 0,
              // 增值税销项税额
              saleTaxAmount: 0,
              // 采购本位币合计成本
              purAmountRmbIncludingTax: 0,
              // 采购不含税（本位币）
              purAmountRmbExcludingTax: 0,
              // 增值税进项税额
              purTaxAmount: 0,
              // 国内短运费(工厂到仓库)合计 数量/计费标准 * 费率
              transportWarehouseFeeTotal: 0,
              // 国内短运费(仓库到客户)合计 数量/计费标准 * 费率
              transportCustomerFeeTotal: 0,
              // 仓储费合计 数量/计费标准 * 费率
              storageFeeTotal: 0,
              // 银行手续费合计  销售额价税合计 * 费率
              bankFeeTotal: 0,
              // 营业税金及附加  （销项税额 - 进项税额）* 营业税金及附加计提比率
              businessTaxAndSurcharges: 0,
              // 运费印花税  （国内短运费(工厂到仓库)合计 + 国内短运费(仓库到客户)合计） * 运费印花税比例
              transportStampTax: 0,
              // 保险印花税  保险费合计 * 保险费印花税比例
              insuranceStampTax: 0,
              // 销售合同印花税  销售额价税合计* 贸易合同印花税收取比例
              saleContractStampTax: 0,
              // 采购合同印花税  采购款价税合计* 贸易合同印花税收取比例
              purContractStampTax: 0,
              // 印花税合计 运费印花税 + 保险印花税 + 销售合同印花税 + 采购合同印花税
              stampTaxTotalAmount: 0,
              // 增值税原币金额 合同原币金额 / (1 + 头信息税率)
              vatAmount: 0,
              // 增值税本位币金额  增值税原币金额 * 汇率
              vatAmountRmb: 0,
              // 成本合计  不含税采购成本 + 国内短运费(工厂到仓库)合计 + 国内短运费(仓库到客户)合计 + 保险费合计 + 仓储费合计 + 资金占压利息（货款）合计 + 资金占压利息（费用）合计 + 银行手续费合计 + 融资利息合计 + 其他费用合计 + 营业税金及附加 + 印花税合计
              totalCost: 0,
              // 订单利润  不含税销售额 - 成本合计
              profit: 0,
              // 利润率   订单利润 / 不含税销售额
              profitRate: 0,
              grossProfit: 0,
              grossProfitRate: 0,
              functionalCurrency: '',
                purContractList: [],
                discountList: [],
                emptyProfitNameList: ['整单销售额', '成本金额', '库存占用利息', '放账资金利息', '税差', '利润', '利润率'],
                fieldList: ['amountIncludeTax', 'amountExcludeTax', 'invCosts', 'sellingCosts', 'taxDiffAmount', 'profit', 'profitRate'],
                profitData: [],
                loading: false,
                finished: false,
                activeNames: '1',
                hostName: '',
                task: '',
                personId: '',
                approvalShow: false,
                customSubmitBtnName: ['同意', '不同意'],
                id: '',
                key: '',
                title: '',
                detail: null,
                active: 0,
                businessKey: '',
                woCode: 'saleContractOrder',
                processInstanceName: '境内销售合同',
                mappingId: '',
                taskInstanceId: '',
                currentNode: '',
                variables: {},
                taskType: '',
              ruleForm: {
                id: '',
                orgName: '',
                contractNo: '',
                contractType: '',
                status: 'DRAFT',
                finalStatus: 'NOT_END',
                bizUserId: '',
                bizUserName: '',
                signDate: '',
                effectiveDate: '',
                customerId: '',
                customerName: '',
                area: '',
                expandSharing: '',
                expandSharingName: '',
                paymentMethod: '',
                taxType: '20',
                bizDesc: '',
                contractAmount: '',
                signPlace: '北京',
                overLoadingRate: 0,
                deliveryDate: '',
                purType: '',
                purContractId: '',
                purContractNumber: '',
                majorSection: '',
                isOrder: 'N',
                bizManageNo: '',
                receiveOrg: '',
                receiveAddr: '',
                receiveVehicleNo: '',
                receiver: '',
                receiveTel: '',
                deliveryType: '',
                settleType: '',
                transportInvoice: '',
                deliveryPerson1: '',
                deliveryPerson1Cert: '',
                deliveryPerson1Mobile: '',
                deliveryPerson2: '',
                deliveryPerson2Cert: '',
                deliveryPerson2Mobile: '',
                deliveryPerson3: '',
                deliveryPerson3Cert: '',
                deliveryPerson3Mobile: '',
                orgId: '',
                deptId: '',
                orderStatus: '',
                processInstanceId: '',
                createUserName: '',
                lastmodifiedUserName: '',
                parentContractNo: '',
                applyDate: '',
                finalCustomerName: '',
                finalCustomerId: '',
                ecOrderSource: '',
                isWholeOrderSale: '',
                isReturnGoods: 'N',
                isStandardContract: 'Y',
                contractTemplateId: '',
                contractTemplateName: '',
                bizArea: '',
                version: 0,
                isUse: 'N',
                source: '',
                commodityDetailList: [],
                paymentList: [],
                unionContractList: [],
                deliveryInfoList: [],
                consignorInfoList: [],
                profitList: [],
                boList: [],
                profit: {},
                isOldVersion: 'N',
                originalContractNo: '',
                taxRate: '5',
                bidId: '',
                bidCode: '',
                bomCode: '',
                bomId: '',
                assistantName: '',
                assistantId: '',
                city: '',
                isCompanyContractTemplate: '',
                isNewCustomer: '',
                businessCondition: '',
                isRelatedTransaction: 'N',
                expectedDeliveryDate: '',
                purchaseSource: '',
                commodityQuantity: 0,
                domesticFreight: 0,
                profitRate: 0,
                leaseStartDate: '',
                leaseEndDate: '',
                leaseDuration: 0,
                soldOnCreditDay: 0,
                fundsOccupyInterest: '',
                annualInterestRate: 5,
                isMajorContract: 'N',
                isPayCommission: 'N',
                isIronOreBusiness: 'N',
                isFundAdvanceBusiness: 'N',
                isRiskBusiness: 'N',
                riskLvl: '',
                isCustomizeProduct: 'N',
                exchangeRateRmb: 0,
                contractAmountRmb: 0,
                isConsignmentBusiness: '',
                boHasDetail: '',
                contractAmountUsd: 0,
                exchangeRateUsd: 0,
                currency: ''
              },
                executionInfo: {
                  deliverySituation: '未知',
                  collectedAmount: '',
                  uncollectedAmount: '',
                  shippedAmount: '',
                  unshippedAmount: '',
                  invoicedAmount: '',
                  uninvoicedAmount: ''
                }
            };
        },
        props: {},
        methods: {
          /**
           * 根据文件id和文件名生成预览链接
           * @param id 附件id
           * @param fileName 附件名
           * @returns {string}
           */
          handleFileView (id, fileName) {
            this.$FilePrvwUtils.openFile(id,fileName);
          },
          downloadAttachment(id, fileName) {
            this.$myHttp({
              method: "post",
              url: "/microarch/sys/sysAttchement/download",
              data: {
                "entity": {
                  "id": id
                }
              },
              responseType: "arraybuffer"
            }).then(res => {
              // 成功回调
              var headers = res.headers;
              var blob = new Blob([res.data], {
                type: headers["content-type"]
              });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
            });
          },
          getAttachmentList() {
            this.$myHttp({
              method: "post",
              url: "/microarch/sys/sysAttchement/list",
              data: {
                "entity": {
                  "bizId": this.ruleForm.id
                }
              }
            }).then(res => {
              let backData = res.data;
              if (backData) {
                this.attachmentList = backData;
              }
            });
          },
        calculateProfit (isFirstSave = false) {
            let _this = this;
            _this.taxRate = Array.from(new Set(this.ruleForm.commodityDetailList.map(it => util.formatAmount((it.taxRate || 0)) + '%'))).join(',');
            _this.purTaxRate = Array.from(new Set(this.ruleForm.commodityDetailList.map(it => util.formatAmount((it.purTaxRate || 0)) + '%'))).join(',');
            _this.saleAmountIncludeTax = 0;
            _this.saleAmountExcludeTax = 0;
            _this.saleTaxAmount = 0;
            // 采购本位币合计成本
            _this.purAmountRmbIncludingTax = 0;
            // 采购不含税（本位币）
            _this.purAmountRmbExcludingTax = 0;
            _this.purTaxAmount = 0;
            let exchangeRateRmb = _this.ruleForm.exchangeRateRmb || 0;
            _this.ruleForm.commodityDetailList.forEach(it => {
                let saleAmountIncludeTax = Number(Number(it.amountIncludingTax || 0).toFixed(2));
                let saleAmountIncludeTaxRmb = Number(Number(saleAmountIncludeTax * exchangeRateRmb).toFixed(2));
                let saleAmountExcludeTax = Number(Number(it.amountExcludingTax || 0).toFixed(2));
                let saleAmountExcludeTaxRmb = Number(Number(saleAmountExcludeTax * exchangeRateRmb).toFixed(2));
                _this.saleAmountIncludeTax += saleAmountIncludeTaxRmb;
                _this.saleAmountExcludeTax += saleAmountExcludeTaxRmb;
                _this.saleTaxAmount += Number((Number(it.taxAmount) || 0).toFixed(2));
                _this.purAmountRmbIncludingTax += Number((Number(it.purAmountRmbIncludingTax) || 0).toFixed(2));
                let purTaxRate = (it.purTaxRate || 0) * 0.01;
                let purAmountRmbExcludeTax = Number((it.purAmountRmbIncludingTax / (1 + purTaxRate)).toFixed(2));
                let purVatAmountRmb = purAmountRmbExcludeTax * purTaxRate;
                _this.purAmountRmbExcludingTax += purAmountRmbExcludeTax;
                _this.purTaxAmount += Number((purVatAmountRmb).toFixed(2));
            });
            if (!_this.ruleForm.profit.fundsOccupyInterest) {
                _this.ruleForm.profit.fundsOccupyInterest = this.saleAmountIncludeTax;
            }
            if (!_this.ruleForm.profit.fundsOccupyInterestRate) {
                _this.ruleForm.profit.fundsOccupyInterestRate = _this.ruleForm.soldOnCreditDay;
            }
            if (_this.ruleForm.contractType === '2') {
                _this.calculateAllRowByStorage();
            } else {
                _this.calculateAllRow();
            }
        },
          calculateAllRowByStorage () {
            this.grossProfit = (this.ruleForm.contractAmountRmb || 0) - (this.purAmountRmbIncludingTax || 0) - (this.ruleForm.profit.transportWarehouseFee || 0) - (this.ruleForm.fundsOccupyInterest || 0) - (this.ruleForm.profit.otherFee || 0);
            this.grossProfitRate = Number((this.grossProfit / this.ruleForm.contractAmountRmb * 100).toFixed(2));
            this.ruleForm.profitRate = this.grossProfitRate;
            this.ruleForm.profitAmount = this.grossProfit;
          },
          calculateAllRow (isFirstSave) {
            // 国内短运费(工厂到仓库)合计 数量/计费标准 * 费率
            this.transportWarehouseFeeTotal = (this.ruleForm.profit.transportWarehouseFee || 0) * (this.ruleForm.profit.transportWarehouseFeeRate || 0);
            // 国内短运费(仓库到客户)合计 数量/计费标准 * 费率
            this.transportCustomerFeeTotal = (this.ruleForm.profit.transportCustomerFee || 0) * (this.ruleForm.profit.transportCustomerFeeRate || 0);
            // 仓储费合计 数量/计费标准 * 费率
            this.storageFeeTotal = (this.ruleForm.profit.storageFee || 0) * (this.ruleForm.profit.storageFeeRate || 0);
            if (isFirstSave) { // 第一次保存时自动计算
              // 资金占压利息（货款）合计 = 数量/计费标准 * 费率 * 6.6% / 360
              this.ruleForm.profit.fundsOccupyInterestTotal = (this.ruleForm.profit.fundsOccupyInterest || 0) * (this.ruleForm.profit.fundsOccupyInterestRate || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01) / 365;
              // 资金占压利息（费用）合计 = 数量/计费标准 * 费率 * 6.6% / 360
              this.ruleForm.profit.fundsOccupyInterestFeeTotal = (this.ruleForm.profit.fundsOccupyInterestFee || 0) * (this.ruleForm.profit.fundsOccupyInterestFeeRate || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01) / 365;
            }
            // 银行手续费合计  销售额价税合计 * 费率
            this.bankFeeTotal = (this.saleAmountIncludeTax || 0) * (this.ruleForm.profit.bankFeeRate || 0) * 0.01;
            // 营业税金及附加  （销项税额 - 进项税额）* 营业税金及附加计提比率
            this.businessTaxAndSurcharges = (this.saleTaxAmount - this.purTaxAmount) * (this.ruleForm.profit.additionalRatio || 0) * 0.01;
            // 运费印花税  （国内短运费(工厂到仓库)合计 + 国内短运费(仓库到客户)合计） * 运费印花税比例
            this.transportStampTax = (this.transportWarehouseFeeTotal + this.transportCustomerFeeTotal) * (this.ruleForm.profit.transportStampTaxRatio || 0) * 0.01;
            // 保险印花税  保险费合计 * 保险费印花税比例
            this.insuranceStampTax = (this.ruleForm.profit.insurance || 0) * ((this.ruleForm.profit.insuranceStampTaxRatio || 0.0) * 0.01);
            // 销售合同印花税  销售额价税合计* 贸易合同印花税收取比例
            this.saleContractStampTax = this.saleAmountIncludeTax * ((this.ruleForm.profit.contractStampTaxRatio || 0) * 0.01);
            // 采购合同印花税  采购款价税合计* 贸易合同印花税收取比例
            this.purContractStampTax = this.purAmountRmbIncludingTax * ((this.ruleForm.profit.contractStampTaxRatio || 0) * 0.01);
            // 印花税合计 运费印花税 + 保险印花税 + 销售合同印花税 + 采购合同印花税
            this.stampTaxTotalAmount = this.transportStampTax + this.insuranceStampTax + this.saleContractStampTax + this.purContractStampTax;

            // 增值税原币金额 合同原币金额 / (1 + 头信息税率)
            let isVatRateIsNotNumber = isNaN(this.ruleForm.taxRate);
            if (isVatRateIsNotNumber) {
              this.vatAmount = 0;
            } else {
              this.vatAmount = this.ruleForm.contractAmount - (this.ruleForm.contractAmount / (1 + (Number(this.ruleForm.taxRate || 0) * 0.01)));
            }
            // 增值税本位币金额  增值税原币金额 * 汇率
            this.vatAmountRmb = this.vatAmount * this.ruleForm.exchangeRateRmb;

            // 成本合计
            // 不含税采购成本 + 国内短运费(工厂到仓库)合计 + 国内短运费(仓库到客户)合计 + 保险费合计 + 仓储费合计 + 资金占压利息（货款）合计 + 资金占压利息（费用）合计 + 银行手续费合计 + 融资利息合计 + 其他费用合计 + 营业税金及附加 + 印花税合计
            this.totalCost = (this.purAmountRmbExcludingTax || 0) + // 采购成本（含税金额合计）
              (this.transportWarehouseFeeTotal || 0) + // 国内端运费(工厂到仓库) 合计
              (this.transportCustomerFeeTotal || 0) + // 国内端运费(仓库到客户) 合计
              (this.ruleForm.profit.insurance || 0) + // 保险费 合计
              (this.storageFeeTotal || 0) + // 仓储费 合计
              (this.ruleForm.profit.fundsOccupyInterestTotal || 0) + // 资金占压利息（货款）合计
              (this.ruleForm.profit.fundsOccupyInterestFeeTotal || 0) + // 资金占压利息（费用）合计
              (this.bankFeeTotal || 0) + // 银行手续费 合计
              (this.ruleForm.profit.financingInterest || 0) + // 融资利息 合计
              (this.ruleForm.profit.otherFee || 0) + // 其他费用 合计
              (this.businessTaxAndSurcharges || 0) + // 营业税金及附加
              (this.stampTaxTotalAmount || 0); // 印花税合计

            debugger;
            // 订单利润  不含税销售额 - 成本合计
            this.profit = this.saleAmountExcludeTax - this.totalCost;
            // 利润率   订单利润 / 不含税销售额
            this.profitRate = Number((this.profit / this.saleAmountExcludeTax * 100).toFixed(2));
            this.ruleForm.profitRate = this.profitRate;
            this.ruleForm.profitAmount = this.profit;
          },
            gotoBusinessPage (name, item) {
                this.$router.push({
                    'name':  name,
                    'params': {
                        'row':  item,
                        'pageType': 'view'
                    },
                    'query': {
                        'businessKey': item.businessKey,
                        'type': 'transated',
                        'row':  JSON.stringify( item ),
                        'pageType': 'view',
                        't': new Date().getTime()
                    }
                });
            },
            goBack () {
                let pageType = this.$router.currentRoute.query.type;
                this.$router.push({
                    name: 'workFlow/workFlowList',
                    'query': {
                        'type': pageType
                    }
                });
            },
            getPurContractList () {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/purCooperativeHeader/list',
                    data: {
                        where: [{
                            'field': 'saleContractNo',
                            'opt': '=',
                            'value': this.ruleForm.contractNo,
                            'assemble': 'and'
                        }]
                    }
                }).then(res => {
                    this.purContractList = res.data.rows;
                });
            },
            getDiscountInfoList() {
                if (!this.ruleForm.discountInfoList || this.ruleForm.discountInfoList.length === 0) {
                    return;
                }
                let applyCodeList = this.ruleForm.discountInfoList.map(it => it.discountApplyNo).filter(it => !!it);
                if (applyCodeList.length === 0) {
                    return;
                }
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/pur/application/list',
                    data: {
                        where: [{
                            'field': 'code',
                            'opt': 'in',
                            'value': applyCodeList.join(','),
                            'assemble': 'and'
                        }]
                    }
                }).then(res => {
                    this.discountList = res.data.rows;
                });
            },
            getProcessMappingId() {
                var userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
                    data: {
                        entity: {
                            woCode: this.woCode,
                            orgId: userInfo.companyId,
                            deptId: userInfo.deptId
                        }
                    }
                }).then(res => {
                    if (res.data.ext.code === '2000') {
                        this.mappingId = res.data.ext.mappingId;
                    } else {
                        Toast.fail({
                            message: res.data.ext.message,
                            type: 'error',
                            duration: 1500
                        });
                    }
                });
            },
            fetchData () {
                this.$myHttp({
                    url: '/microarch/sale/contract/trade/saleContractOrder/executionInfo/' + this.ruleForm.id,
                    method: 'get'
                }).then(res => {
                    this.executionInfo = res.data.entity;
                });
            },
            backfilleditData(id) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/sale/contract/trade/saleContractOrder/view',
                    data: {
                        entity: {
                            id: id
                        }
                    }
                }).then(res => {
                    // 成功回调方法
                    var data = res.data.entity;
                    this.ruleForm = data;
                    this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
                    this.variables.activitiData = res.data.entity;
                    this.getAttachmentList();
                    this.getProcessMappingId();
                    this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
                    if (this.$route.query.type && this.taskInstanceId) {
                        this.approvalShow = true;
                    }
                    this.calculateProfit();
                    this.getDiscountInfoList();
                    this.getPurContractList();
                    this.fetchData();
                });
            },
            // 计算盈亏
            // calculateProfit() {
            //     let profit = this.ruleForm.profit;
            //     if (!profit) {
            //         profit = {};
            //     }
            //     let totalAmount = 0.00;
            //     let totalCost = 0.00;
            //     let taxDiffAmount = 0;
            //     this.ruleForm.commodityDetailList.forEach(it => {
            //         totalAmount += it.amountIncludingTax;
            //         totalCost += it.purCostAmountIncludeTax;
            //         taxDiffAmount += Number((Number(it.taxDiff) || 0).toFixed(2));
            //     });
            //     this.ruleForm.taxDiffAmount = taxDiffAmount;
            //     profit.amountIncludeTax = this.ruleForm.contractAmount;
            //     profit.sellingCosts = this.ruleForm.sellingCosts;
            //     profit.invCosts = this.ruleForm.invCosts;
            //     profit.taxDiffAmount = taxDiffAmount;
            //
            //     let sellingCosts = profit.sellingCosts || 0;
            //     let invCosts = profit.invCosts || 0;
            //
            //     profit.amountIncludeTax = totalAmount;
            //     profit.amountExcludeTax = totalCost;
            //     profit.invCosts = invCosts;
            //     profit.sellingCosts = sellingCosts;
            //     let diff = totalAmount - totalCost - invCosts - sellingCosts - taxDiffAmount;
            //     profit.profit = diff;
            //     profit.profitRate = totalAmount === 0 ? 0 : ((diff / totalAmount) * 100).toFixed(2);
            //     this.ruleForm.profit = profit;
            //     this.fillProfitData();
            // },
            fillProfitData() {
                this.profitData = [];
                this.emptyProfitNameList.forEach((data, index) => {
                    let row = [];
                    let field = '';
                    if (data === '利润率') {
                        field = this.fieldList[index];
                        row = {
                            itemValue: this.ruleForm.profit[field] + '%',
                            itemName: data,
                            index: this.profitData.length
                        };
                    } else {
                        field = this.fieldList[index];
                        row = {
                            itemValue: this.ruleForm.profit[field],
                            itemName: data,
                            index: this.profitData.length
                        };
                    }
                    this.profitData.push(row);
                });
            },
            setApprovalData(row) {
                if (row && row.pid) {
                    this.ruleForm.id = row.businessKey;
                    this.taskInstanceId = row.id;
                    this.taskType = row.taskType;
                }
            },
            /**
             * 业务提交之前校验
             * */
            checkBeforeProcess(type) {
                if (type) {
                    if (type === 'beforeAddsign') {
                        this.$refs.demoTemperatureEdit.beforeAddsign();
                    } else if (type === 'afterAddsign') {
                        this.$refs.demoTemperatureEdit.afterAddsign();
                    } else if (type === 'endProcess') {
                        this.$refs.demoTemperatureEdit.endProcess();
                    } else if (type === 'repelProcess') {
                        this.repelProcess();
                    } else {
                        if (!this.taskInstanceId) {
                            var params = {
                                businessKey: this.ruleForm.id,
                                mappingId: this.mappingId,
                                processInstanceName: this.processInstanceName,
                                userName: sessionStorage.getItem('userName'),
                                variables: this.variables
                            };
                            this.startProcessAction(params);
                        } else {
                            this.$refs.demoTemperatureEdit.submit();
                        }
                    }
                } else {
                    Toast.fail({
                        message: this.$t('sale.数据校验失败'),
                        type: 'warning'
                    });
                }
            },
            // 业务发起流程
            startProcessAction(params) {
                if (params.mappingId) {
                    params.mappingId = this.mappingId;
                }
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/sale/contract/trade/saleContractOrder/start',
                    data: params
                })
                    .then(res => {
                        var backData = res.data.ext;
                        if (backData) {
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                            this.$refs.demoTemperatureEdit.isStart = true;
                            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
                            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
                            this.$refs.demoTemperatureEdit.isFirstNode = true;
                            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
                            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
                            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
                        } else {
                            Toast.fail({
                                message: this.$t('sale.流程发起异常'),
                                type: 'warning'
                            });
                            this.$refs.demoTemperatureEdit.submitLoading = false;
                        }
                    })
                    .catch(_ => {
                        this.$refs.demoTemperatureEdit.submitLoading = false;
                    });
            },
            // 业务提交流程
            submitProcessAction(params) {
                this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
                this.$myHttp({
                    method: 'post',
                    url: '/microarch/sale/contract/trade/saleContractOrder/submit',
                    data: params
                })
                    .then(res => {
                        if (res.data.ext.success) {
                            this.processCallback(null, null, this.taskType);
                        } else {
                            Toast.fail({
                                message: res.data.ext.msg,
                                type: 'warning'
                            });
                        }
                    })
                    .catch(_ => {
                    });
            },
            /***
             * 业务撤销流程
             */
            repelProcess() {
                var _this = this;
                _this
                    .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
                        cancelButtonClass: 'sysBackBtn'
                    })
                    .then(_ => {
                        this.$myHttp({
                            method: 'post',
                            url: '/microarch/sale/contract/trade/saleContractOrder/undoProcess',
                            data: {
                                entity: {
                                    id: this.ruleForm.id,
                                    processInstanceId: this.ruleForm.processInstanceId
                                }
                            }
                        })
                            .then(res => {
                                if (res.data.success === undefined || res.data.success) {
                                    Toast.fail({
                                        message: this.$t('sale.撤销成功'),
                                        type: 'success'
                                    });
                                    this.processCallback(null, null, this.taskType);
                                } else {
                                    Toast.fail({
                                        message: res.data.msg,
                                        type: 'warning'
                                    });
                                }
                            })
                            .catch(_ => {
                            });
                    })
                    .catch(_ => {
                    });
            },

            /***
             * 关闭发起流程审批窗口
             * 删除流程实例，回滚业务状态
             */
            closeStartCallback(pid) {
                this.$myHttp({
                    method: 'POST',
                    url: '/microarch/sale/contract/trade/saleContractOrder/deleteProcess',
                    data: {
                        entity: {
                            id: this.ruleForm.id,
                            processInstanceId: pid
                        }
                    }
                })
                    .then(res => {
                        this.$refs.demoTemperatureEdit.dialogVisible = false;
                        this.$refs.demoTemperatureEdit.taskId = '';
                        this.$refs.demoTemperatureEdit.processInstanceId = '';
                        this.processCallback(null, null, this.taskType);
                    })
                    .catch(_ => {
                    });
            },

            /**
             * 提交之前校验
             * */
            beforeSubmitCallBack(params) {
                // 业务参数校验 此时可以从params中获取提交审批所有的参数
                // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
                // 不通过给出相应的提示或者触发其他业务操作
                // let selectUserArray = params.candidateUserIds[params.nextId]
                // console.log(selectUserArray);
                this.submitProcessAction(params);
            },
            /**
             * 流程审批之后业务数据更改
             * */
            processCallback(piid, optionType, taskType) {
                var _this = this;
                // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
                // // 成功回调方法
                // if (taskType === 'transated') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/haveList'
                //   });
                // } else if (taskType === 'waitfor') {
                //   _this.$router.push({
                //     name: 'sys/workFlow/toDoList'
                //   });
                // } else {
                //   this.$router.push({
                //     name: 'sale/return/saleReturnApplyList'
                //   });
                //   this.$bus.emit('saleReturnApply-SaveSuccess');
                // }
                this.$router.go(-1);
            }
        },
        created() {
        },
        mounted() {
            this.functionalCurrency = util.getCurrencyName(this.ruleForm.orgId);
            let selectRow = JSON.parse(this.$route.query.row);
            if (selectRow) {
                this.ruleForm.id = selectRow.id;
                if (selectRow.businessKey) {
                    this.ruleForm.id = selectRow.businessKey;
                }
            }

            if (selectRow) {
                this.setApprovalData(selectRow);
            }
            if (this.ruleForm.id) {
                this.backfilleditData(this.ruleForm.id);
            }
        },
        filters: {
            setDict(v, dictName) {
                return util.setDict(v, dictName);
            },
          formatAmount(v, decimal = 2, isCovering = true) {
            return util.formatAmount(v, decimal, isCovering);
          }
        }
    };
</script>

<style lang="less" scoped>
  .top {
    overflow: hidden;

    .left-col {
      float: left;
      width: 60%;
    }

    .img {
      float: right;
    }
  }

  .visitor-details {
    p {
      color: #aab2bd;
    }

    h5 {
      /*font-weight: bold;*/
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .img {
    width: 40%;
    text-align: center;
    padding: 10px;
    position: relative;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  .panel-list {
    .panel {
      margin-top: 5px;
      background: #fff;
    }

    .divline {
      padding: 0 0 5px 0;
    }

    .icon-status {
      width: 50px;
      height: 45px;
      position: absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      bottom: 36px;
      right: 5px;
    }

    .type-0 {
      background-image: url('../../../images/icon-queryStatus-0.png');
    }

    .type-1 {
      background-image: url('../../../images/icon-queryStatus-1.png');
    }

    .type-2 {
      background-image: url('../../../images/icon-queryStatus-2.png');
    }

    .type-3 {
      background-image: url('../../../images/icon-queryStatus-3.png');
    }

    .type-4 {
      background-image: url('../../../images/icon-queryStatus-4.png');
    }
  }

  .table {
    margin-top: 5px;

    td {
      background: #f6f6f6;
      height: auto;
      padding: 8px 5px;
      border-bottom: 5px solid #fff;
    }
  }

  .personal-table {
    td {
      text-align: left;
      word-break: break-all;
    }

    img {
      vertical-align: middle;
    }

    .idcard {
      font-size: 12px;
      padding: 0;
    }
  }

  .car-table {
    td {
      text-align: center;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .visit-record {
    padding: 2px 0 0;

    ul {
      li {
        padding: 3px 0;
      }
    }
  }
</style>
